import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'prefix' },
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)},
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'organization',
    loadChildren: () => import('./pages/organization/organization.module').then( m => m.OrganizationPageModule)
  },
  {
    path: 'groups',
    loadChildren: () => import('./pages/groups/groups.module').then( m => m.GroupsPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'garages',
    loadChildren: () => import('./pages/garages/garages.module').then( m => m.GaragesPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },

  {
    path: 'addorganizationmodal',
    loadChildren: () => import('./modals/addorganizationmodal/addorganizationmodal.module').then( m => m.AddorganizationmodalPageModule)
  },
  {
    path: 'addusersmodal',
    loadChildren: () => import('./modals/addusersmodal/addusersmodal.module').then( m => m.AddusersmodalPageModule)
  },
  {
    path: 'addgaragemodal',
    loadChildren: () => import('./modals/addgaragemodal/addgaragemodal.module').then( m => m.AddgaragemodalPageModule)
  },
  {
    path: 'activityhistory',
    loadChildren: () => import('./pages/activityhistory/activityhistory.module').then( m => m.ActivityhistoryPageModule)
  },
  {
    path: 'addgaragefreezehistorymodal',
    loadChildren: () => import('./modals/addgaragefreezehistorymodal/addgaragefreezehistorymodal.module').then( m => m.AddgaragefreezehistorymodalPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
