import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalsService } from './globals';
import { Constants } from './constants';
import { Storage } from '@ionic/storage';
@Injectable({
    providedIn: 'root'
})
export class RequestHelper {


    constructor(private globals: GlobalsService, private storage: Storage) {

    }

    // Login Url
    public GetUrlForLogin() {
        const url = Constants.serviceURL + "/AuthenticateUserV2?isPortal=true";
        return url;
    }
    public GetUrlForOrganization() {
        const url = Constants.serviceURL + "/GetOrganizationList";
        return url;
    }
    public GetUrlForAllUsers() {
        const url = Constants.serviceURL + "/GetUsersList?email="+this.globals.userInfo.userName;
        return url;
    }
     public GetUrlForGarageMappedUsers(controlItemID) {
        const url = Constants.serviceURL + "/GetGarageMappedUsersList?ControlItemID="+controlItemID;
        return url;
    }
    public GetUrlForAllGarages(controlType) {
        const url = Constants.serviceURL + "/GetGaragesListPortal?controlType=" + controlType;
        return url;
    }
    public GetUrlForActivityHistory(count) {
        const url = Constants.serviceURL + "/GetActivityHistoryList?isAll=true&count="+count;
        return url;
    }
    public GetUrlForGarageFreezeActivityHistoryData(controlItemID) {
        const url = Constants.serviceURL + "/GetGarageFreezeActivityHistoryData?controlItemID="+controlItemID;
        return url;
    }
    public GetUrlForUserGarages(email) {
        const url = Constants.serviceURL + "/GetUserMappedGaragesList?email=" + email;
        return url;
    }

    public PostUrlForSaveOrganization() {
        const url = Constants.serviceURL + "/InsertUpdateOrganization";
        return url;
    }
    public PostUrlForSaveUser() {
        const url = Constants.serviceURL + "/InsertUpdateUser";
        return url;
    }
    public PostUrlForSaveGarage() {
        const url = Constants.serviceURL + "/InsertUpdateGarages";
        return url;
    }
    public PostUrlForSaveGarageMappedUsers() {
        const url = Constants.serviceURL + "/UpdateGarageSupervisor";
        return url;
    }
    public deleteUrlForOrganization(organizationID) {
        const url = Constants.serviceURL + "/DeleteOrganization?organizationID=" + organizationID;
        return url;
    }
    public deleteUrlForGarage(controlItemID) {
        const url = Constants.serviceURL + "/DeleteGarage?controlItemID=" + controlItemID;
        return url;
    }
    public deleteUrlForUser(userID) {
        const url = Constants.serviceURL + "/DeleteUser?userID=" + userID;
        return url;
    }
    public GetUrlForSaveActivityHistory() {
        const url = Constants.serviceURL + "/InsertActivityHistory";
        return url;
    }
    public GetUrlForCallParticleAPI() {
        const url = Constants.serviceURL + "/CallParticleAPI";
        return url;
    }
    public SetBasicAuthEncodedString(username, password) {
        return window.btoa(username + ':' + password);
    }
    public getRequestHeader() {
        let reqHeader: any = {};
        if (this.globals.userInfo.userName == "" || this.globals.userInfo.password == "") {
            this.storage.get('username').then((val) => {
                this.globals.userInfo.userName = val;
                this.storage.get('password').then((val) => {
                    this.globals.userInfo.password = val;
                    reqHeader = new HttpHeaders({
                        Authorization: 'Basic ' + this.SetBasicAuthEncodedString(this.globals.userInfo.userName, this.globals.userInfo.password),
            
                    });
                    return reqHeader;
                });
            });

        } else{
            reqHeader = new HttpHeaders({
                Authorization: 'Basic ' + this.SetBasicAuthEncodedString(this.globals.userInfo.userName, this.globals.userInfo.password),
    
            });
            return reqHeader;
        }
        
        
        
    }
}
