import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../shared/common';
import { RequestHelper } from '../shared/requesthelper';
import { GlobalsService } from '../shared/globals';
import { Constants } from '../shared/constants';
import { timeout } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(public http: HttpClient,
    public commonService: CommonService,
    public requestHelper: RequestHelper, ) { }

  getOrganizationData() {
    return new Promise((resolve, reject) => {
      const url = this.requestHelper.GetUrlForOrganization();
      const reqHeaders = this.requestHelper.getRequestHeader();
      this.http.get(url,{ headers: reqHeaders }  )
        .pipe(timeout(Constants.TIMEOUT))
        .subscribe(
          (data: any) => {
            resolve(data.Organizations);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  deleteOrganizationsData(organizationID) {
    return new Promise((resolve, reject) => {
      const url = this.requestHelper.deleteUrlForOrganization(organizationID);
      const reqHeaders = this.requestHelper.getRequestHeader();
      this.http.get(url,{ headers: reqHeaders }  )
        .pipe(timeout(Constants.TIMEOUT))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  // getAllUsers() {
  //   return new Promise((resolve, reject) => {
  //     const url = this.requestHelper.GetUrlForAllUsers();
  //     const reqHeaders = this.requestHelper.getRequestHeader();
  //     this.http.get(url,{ headers: reqHeaders }  )
  //       .pipe(timeout(Constants.TIMEOUT))
  //       .subscribe(
  //         (data: any) => {
  //           resolve(data.users);
  //         },
  //         (error) => {
  //           reject(error);
  //         }
  //       );
  //   });
  // }
  saveOrganization(body) {
    return new Promise((resolve, reject) => {
      const url = this.requestHelper.PostUrlForSaveOrganization();
      const reqHeaders = this.requestHelper.getRequestHeader();
      this.http.post(url, body,{ headers: reqHeaders } )
        .pipe(timeout(Constants.TIMEOUT))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

}
