import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  
  public menuControlItems: any;
  public menuItems:any;
  public userInfo:any={
     userName:'',
     password:'',
     firstName:'',
     lastName:'',
     OrganizationID:'',
     accessToken:'',
     LoggedInUserID:'',
     
  }
  

  

  

 
 

 
}
