import { Component } from '@angular/core';

import { Platform, NavController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuDetails } from '../app/shared/constants';
import { CommonService } from './shared/common';
import { messages } from './shared/constants';
import { Router, NavigationStart } from '@angular/router';
import {GlobalsService} from './shared/globals';
import { Storage } from '@ionic/storage';


export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navigationCtrl: NavController,
    private menuDetails:MenuDetails,
    private commonService: CommonService,
    private route:Router,
    public globals:GlobalsService,
    private menuCtrl: MenuController,
    private storage:Storage
    
  ) {
   
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      // if(this.globals.userInfo.userName == undefined || this.globals.userInfo.userName == null || this.globals.userInfo.userName == '' ){
      //   this.navigationCtrl.navigateRoot('login');
      // } 
      //this.commonService.assignGlobals();
      this.globals.menuItems = this.menuDetails.menuItems;
      this.globals.menuControlItems = this.menuDetails.menuControlItems;
      //var page = this.menuDetails.menuItems.filter(item => item.Selected == true);
    
    });
  }
  public openPage(page) {
    if (!navigator.onLine) {
      this.commonService.showAlert(messages.MESSAGETITLE, messages.NONETWORK, ['OK']);
    } else {
      this.commonService.unHighlightsideMenu();
      this.commonService.highlightsideMenu(page);
      this.storage.set('selectedPage', page);
      this.navigationCtrl.navigateRoot(page.ContentUrl, {queryParams: page.Params});
    }
  }
  logoutClick(){
    this.storage.remove('username');
    this.storage.remove('password');
    this.storage.remove('accessToken');
    this.storage.remove('LoggedInUserID');

    this.storage.remove('lastName');
    this.storage.remove('firstName');
    this.storage.remove('selectedPage');
    this.navigationCtrl.navigateRoot('login');
  }
}
