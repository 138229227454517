import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CommonService } from '../../shared/common';
import { messages } from 'src/app/shared/constants';
import { GlobalsService } from '../../shared/globals';
import { OrganizationService } from '../../services/organization.service';
import { UserService } from '../../services/user.service';
import { AddusersmodalPage } from '../../modals/addusersmodal/addusersmodal.page';

@Component({
  selector: 'app-addorganizationmodal',
  templateUrl: './addorganizationmodal.page.html',
  styleUrls: ['./addorganizationmodal.page.scss'],
})
export class AddorganizationmodalPage implements OnInit {

  public orgStatus = true;
  public orgName = "";
  public orgID = 0;
  public usersList = [];
  public tempUsersList = [];
  dataReturned: any;
  isLoggedInUserEdit = false;
  usersCount = 0;
  constructor(public modalCtrl: ModalController,
    private commonService: CommonService,
    private globals: GlobalsService,
    private OrganizationService: OrganizationService,
    private userService: UserService,

    navParams: NavParams) {
    if (navParams.get('OrganizationID') != undefined &&
      navParams.get('OrganizationID') != null && navParams.get('OrganizationID') != "") {
      this.orgID = navParams.get('OrganizationID');
      this.orgName = navParams.get('OrganizationName');
      this.orgStatus = navParams.get('OrganizationStatus');
      this.usersCount = navParams.get('usersCount');
      this.tempUsersList = navParams.get('usersList');
    }
  }

  ngOnInit() {

    this.commonService.assignGlobals();
    this.usersList=[];
    this.tempUsersList.forEach(element => {
      if(element.OrganizationID == this.orgID){
        this.usersList.push(element);
      }
    });
  }

  dismiss(flag) {
    this.modalCtrl.dismiss({
      'isCanceldismissed': flag
    });
  }
  async onSubmitOrganizationClick() {

    if (this.orgName == "") {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.ORGNAME, ['OK']);
      return;
    }
    if (navigator.onLine) {
      const loader = await this.commonService.showLoading(messages.SAVEORG);
      const body = { OrganizationID: this.orgID, OrganizationName: this.orgName, OrganizationStatus: this.orgStatus, Email: this.globals.userInfo.userName }
      this.OrganizationService
        .saveOrganization(body)
        .then((data: any) => {
          loader.dismiss();
          if (data.Message == 'SUCCESS') {
            this.dismiss(false);
          }
        })
        .catch((e) => {
          loader.dismiss();
          console.log(e.error.text);
          this.commonService.showAlert(messages.ALERTTITLINFORMATION, e.error.text, ['OK']);
        });
    } else {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.PLEASECONNECTTONETWORK, ['OK']);
    }
    //this.dismiss(false);
  }
  onChangeRadioEvent($event) {
    console.log('status ' + $event.target.value);
    this.orgStatus = $event.target.value;
  }
  async presentModal(data) {
    const modal = await this.modalCtrl.create({
      component: AddusersmodalPage,
      componentProps: {
        'data': data,

      }
    });
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data.isCanceldismissed;
        //if(!this.dataReturned){
        this.getAllUsers();
        //}
      }
    });

    return await modal.present();

  }
  onEditUserclick(data) {
    if (data.UserID == this.globals.userInfo.LoggedInUserID) {
      // this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.EDITINPROFILE, ['OK']);
      // return;
    }
    if (data.UserID == this.globals.userInfo.LoggedInUserID) {
      this.isLoggedInUserEdit = true;
    }
    data.isLoggedInUserEdit = this.isLoggedInUserEdit;
    this.presentModal(data);
  }
  onDeleteUserclick(data) {
    if (data.UserID == this.globals.userInfo.LoggedInUserID) {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.CANNOTDELETELOGGEDINUSER, ['OK']);
      return;
    }
    this.commonService.showAlert(messages.MESSAGETITLE, messages.DELETECONFIRM, [
      {
        text: 'Yes',
        handler: () => {
          this.deleteUserData(data.UserID);
        }
      },
      {
        text: 'No',
        role: 'cancel'
      }
    ]);

  }
  async deleteUserData(userID) {
    if (navigator.onLine) {
      const loader = await this.commonService.showLoading(messages.DELETEORGDATA);
      this.userService
        .deleteUserData(userID)
        .then((data: any) => {
          loader.dismiss();
          if (data.Message == 'SUCCESS') {
            this.usersList.forEach(function (item, index, object) {
              if (item.UserID === userID) {
                object.splice(index, 1);
              }
            });

          }
        })
        .catch((e) => {
          loader.dismiss();
        });
    } else {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.PLEASECONNECTTONETWORK, ['OK']);
    }

  }
  async getAllUsers() {
    if (navigator.onLine) {
      var self = this;
      this.usersList = [];
      const loader = await this.commonService.showLoading(messages.ALLUSERS);
      this.userService
        .getAllUsers()
        .then((data: any) => {
          loader.dismiss();
          if (data.length > 0) {
            data.forEach(function (element, index, object) {
              if (element.UserID == self.globals.userInfo.LoggedInUserID) {
                //object.splice(index, 1);
              }
              if (element.IsActive) {
                element.Status = 'Active';
              } else {
                element.Status = 'InActive';
              }
              element.fullName = element.LastName + ", " + element.FirstName;
            });
            this.usersList = data;
          }
        })
        .catch((e) => {
          loader.dismiss();
        });
    } else {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.PLEASECONNECTTONETWORK, ['OK']);
    }
  }

}
