import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../shared/common';
import { RequestHelper } from '../shared/requesthelper';
import { GlobalsService } from '../shared/globals';
import { Constants } from '../shared/constants';
import { timeout } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public http: HttpClient,
    public commonService: CommonService,
    public requestHelper: RequestHelper, ) { }
    getAllUsers() {
      return new Promise((resolve, reject) => {
        const url = this.requestHelper.GetUrlForAllUsers();
        const reqHeaders = this.requestHelper.getRequestHeader();
      this.http.get(url,{ headers: reqHeaders }  )
          .pipe(timeout(Constants.TIMEOUT))
          .subscribe(
            (data: any) => {
              resolve(data.users);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
    saveUser(body) {
      return new Promise((resolve, reject) => {
        const url = this.requestHelper.PostUrlForSaveUser();
        const reqHeaders = this.requestHelper.getRequestHeader();
        this.http.post(url, body,{ headers: reqHeaders } )
          .pipe(timeout(Constants.TIMEOUT))
          .subscribe(
            (data: any) => {
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
    deleteUserData(userID) {
      return new Promise((resolve, reject) => {
        const url = this.requestHelper.deleteUrlForUser(userID);
        const reqHeaders = this.requestHelper.getRequestHeader();
      this.http.get(url,{ headers: reqHeaders }  )
          .pipe(timeout(Constants.TIMEOUT))
          .subscribe(
            (data: any) => {
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
}
