import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController, PopoverController, ToastController } from '@ionic/angular';
//import { HttpClient } from '@angular/common/http';
import { GlobalsService } from './globals';
import { messages } from './constants';
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    public commonAlert: any;
    public commonModal: any;
    public commonToast: any;

    constructor(
        //public http: HttpClient,
        private alertCtrl: AlertController,
        private loadingCtrl: LoadingController,
        private modalCtrl: ModalController,
        private popoverCtrl: PopoverController,
        private globals: GlobalsService,
        public toastController: ToastController,
        public storage: Storage
    ) { }





    //


    public isOnline() {
        return new Promise((resolve, reject) => {
            if (!navigator.onLine) {
                this.showAlert(
                    messages.ALERTTITLINFORMATION,
                    messages.PLEASECONNECTTONETWORK,
                    ['Dismiss']
                );
                reject();
            } else {
                resolve("");
            }
        });
    }
    public unHighlightsideMenu() {
        this.globals.menuItems.forEach(element => {
            element.Selected = false;
        });
        this.globals.menuControlItems.forEach(element => {
            element.Selected = false;
        });
    }
    public highlightsideMenu(page) {
        this.globals.menuItems.forEach(element => {
            if (page.Id == element.Id) {
                element.Selected = true;
            }
        });
        this.globals.menuControlItems.forEach(element => {
            if (page.Id == element.Id) {
                element.Selected = true;
            }
        });
    }
    public assignGlobals() {
        console.log('assign globals');
        if (this.globals.userInfo.LoggedInUserID == "") {
            this.storage.get('LoggedInUserID').then((val) => {
                this.globals.userInfo.LoggedInUserID = val;
            });
        }
        if (this.globals.userInfo.userName == "") {
            this.storage.get('username').then((val) => {
                this.globals.userInfo.userName = val;
            });
        }
        if (this.globals.userInfo.password == "") {
            this.storage.get('password').then((val) => {
                this.globals.userInfo.password = val;
            });
        }
        if (this.globals.userInfo.lastName == "") {
            this.storage.get('lastName').then((val) => {
                this.globals.userInfo.lastName = val;
            });
        }
        if (this.globals.userInfo.firstName == "") {
            this.storage.get('firstName').then((val) => {
                this.globals.userInfo.firstName = val;

            });
        }

        if (this.globals.userInfo.accessToken == "") {
            this.storage.get('accessToken').then((val) => {
                this.globals.userInfo.accessToken = val;
            });
        }
        this.storage.get('selectedPage').then((val) => {
            var page = {};
            if (val == null) {
                page = {
                    "Id": "dashboard",
                    "Title": "Dashboard",
                    "Icon": "home",
                    "Selected": false,
                    "ContentUrl": "dashboard",
                    "Params": {
                        "Title": "Dashboard"
                    }
                }
            } else {
                page = val;
            }

            this.unHighlightsideMenu();
            this.highlightsideMenu(page);
        });

    }


    public showError(errorMessage = messages.SERVERERROR) {
        this.showAlert(messages.SERVERERRORTITLE, errorMessage, ['OK']);
    }



    public async showAlert(title: string, message: string, buttons: any[], inputs = []) {
        if (this.commonAlert) {
            this.commonAlert.dismiss();
        }
        this.commonAlert = await this.alertCtrl.create({
            header: title,
            message: message,
            inputs: inputs,
            backdropDismiss: false,
            buttons: buttons
        });
        this.commonAlert.present();
        return this.commonAlert;
    }
    public async presentToast(customMessage) {
        this.commonToast = await this.toastController.create({
            message: customMessage,
            duration: 3000
        });
        this.commonToast.present();
        return this.commonToast;
    }

    public async showLoading(loadingText) {
        const loading = await this.loadingCtrl.create({
            message: loadingText,
            spinner: 'crescent'
        });
        await loading.present();
        return await loading;
    }

    public async showModal(component, componentProps, cssClass) {
        const modal = await this.modalCtrl.create({
            component: component,
            componentProps: componentProps,
            backdropDismiss: false,
            cssClass: cssClass
        })
        await modal.present()
        return await modal;
    }

    public async showPopover(popEvent: any, component: any, params: any) {

        const top = popEvent.y > (popEvent.view.innerHeight - 50) ? (popEvent.y - 10) : (popEvent.y + 10)
        const ev: any = {
            target: {
                getBoundingClientRect: () => {
                    return {
                        top: top,
                        left: popEvent.pageX - popEvent.offsetX
                    };
                }
            }
        };
        const popover = await this.popoverCtrl.create({
            component: component,
            event: ev,
            translucent: true,
            componentProps: params
        });
        await popover.present();
        return await popover
    }


}
