import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { GlobalsService } from '../../shared/globals';
import { CommonService } from '../../shared/common';
import { Storage } from '@ionic/storage';
import { messages, Constants } from 'src/app/shared/constants';
@Component({
  selector: 'custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss'],
})
export class CustomHeaderComponent implements OnInit {
  @Input() customTitle = "";
  @Input() userFullName = "";
  private title = "";
  isDev=false;
  constructor(public globals: GlobalsService,
    public navigationCtrl: NavController,
    public commonService: CommonService
    , private storage: Storage
  ) {

  }

  ngOnInit() {
    this.title = this.customTitle;
    if(Constants.IsDEV==1){
      this.isDev = true;
    }
    if (this.globals.userInfo.firstName == "" || this.globals.userInfo.lastName == "") {
      this.storage.get('firstName').then((val) => {
        this.globals.userInfo.firstName = val;
        this.storage.get('lastName').then((val) => {
          this.globals.userInfo.lastName = val;
        });
      });
    }
  }
  onProfileClick() {
    this.commonService.unHighlightsideMenu();
    //this.navigationCtrl.navigateRoot('/profile', { queryParams: { Title: 'Profile' } });
  }
}
