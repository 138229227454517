import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common';
import { messages } from 'src/app/shared/constants';
import { NavParams, ModalController } from '@ionic/angular';
import { ActivityhistoryService } from '../../services/activityhistory.service';
import { CustomHeaderComponent } from '../../component/custom-header/custom-header.component';
@Component({
  selector: 'app-addgaragefreezehistorymodal',
  templateUrl: './addgaragefreezehistorymodal.page.html',
  styleUrls: ['./addgaragefreezehistorymodal.page.scss'],
})
export class AddgaragefreezehistorymodalPage implements OnInit {
  garageData:any;
  public title = "";
  activityHistory = [];
  constructor(  private customHeader: CustomHeaderComponent,   private commonService: CommonService,   
    public activityHistoryService: ActivityhistoryService,   public navParams: NavParams,public modalCtrl: ModalController) { }

  ngOnInit() {
    this.garageData = Object.assign({}, this.navParams.get('data'));
    this.title = this.garageData.DisplayName;
    this.GetGarageFreezeActivityHistoryData();
  }

  async GetGarageFreezeActivityHistoryData() {
    
    if (navigator.onLine) {
      let loader;
      loader = await this.commonService.showLoading(messages.ALLACTIVITYHISTORY);
      this.activityHistoryService
        .GetGarageFreezeActivityHistoryData(this.garageData.ControlItemID)
        .then((data: any) => {
          loader.dismiss();
          if (data != undefined && data.length > 0) {
            this.activityHistory=data;
          } 
        })
        .catch((e) => {
            loader.dismiss();
        });
    } else {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.PLEASECONNECTTONETWORK, ['OK']);
    }
  }
  dismiss(){
    this.modalCtrl.dismiss();
  }

}
