import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common';
import { messages } from 'src/app/shared/constants';
import { GlobalsService } from '../../shared/globals';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import { UserService } from '../../services/user.service';
import { Md5 } from "md5-typescript";
import { OrganizationService } from '../../services/organization.service';
import { GarageService } from '../../services/garage.service';
import { Storage } from '@ionic/storage';
@Component({
  selector: 'app-addusersmodal',
  templateUrl: './addusersmodal.page.html',
  styleUrls: ['./addusersmodal.page.scss'],
})
export class AddusersmodalPage implements OnInit {
  public userData = {
    UserID: 0, FirstName: '', LastName: '', IsActive: true, Email: '', Password: '', OrganizationID: 0,
    IsDeleted: false, LoggedInEmail: '', ControlItemIDs: '', HasAccessToPortal:false, OverRideGeoFence:false
  }
  public isEditUser = true;
  public organizationData = [];
  garageList = [];
  existingPassword;
  constructor(private commonService: CommonService,
    private globals: GlobalsService,
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public userService: UserService,
    public OrganizationService: OrganizationService,
    public garageService: GarageService,
    private storage: Storage,
    private navigationCtrl: NavController,) {
    this.userData = navParams.get('data');
  }

  ngOnInit() {
    this.commonService.assignGlobals();
    if (this.userData.UserID == 0) {
      this.isEditUser = false;
    }
    this.existingPassword = this.userData.Password;
    this.userData.Password = '';
    this.getOrganizationsData();
    this.getAllGarages();

  }
  dismiss(flag) {
    this.modalCtrl.dismiss({
      'isCanceldismissed': flag,
      'userData': this.userData
    });
  }
  async getUserMappedGarages() {
    if (navigator.onLine) {

      const loader = await this.commonService.showLoading(messages.ALLGARAGES);
      this.garageService
        .getUserMappedGarages(this.userData.Email)
        .then((data: any) => {
          loader.dismiss();
          if (data.length > 0) {
            data.forEach(element => {
              this.garageList.forEach(allElement => {
                if (element.ControlItemID == allElement.ControlItemID) {
                  allElement.isChecked = true;
                }
              });

            });

          }
        })
        .catch((e) => {
          loader.dismiss();
        });
    } else {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.PLEASECONNECTTONETWORK, ['OK']);
    }
  }
  async getAllGarages() {
    if (navigator.onLine) {
      this.garageList = [];
      const loader = await this.commonService.showLoading(messages.ALLGARAGES);
      this.garageService
        .getAllGarages('GARAGE')
        .then((data: any) => {
          loader.dismiss();
          if (data.length > 0) {
            data.forEach(element => {
              element.isChecked = false;
              if (element.IsActive) {
                this.garageList.push(element);
              }
            });
            //this.garageList = data;
            if (this.isEditUser) {
              this.getUserMappedGarages();
            }
          }
        })
        .catch((e) => {
          loader.dismiss();
        });
    } else {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.PLEASECONNECTTONETWORK, ['OK']);
    }
  }
  async getOrganizationsData() {
    if (navigator.onLine) {
      const loader = await this.commonService.showLoading(messages.GETORGANIZATIONDATA);
      this.OrganizationService
        .getOrganizationData()
        .then((data: any) => {
          loader.dismiss();
          if (data.length > 0) {
            data.forEach(element => {
              element.isChecked = false;
              if (element.IsActive) {
                this.organizationData.push(element);
              }

            });


          }
        })
        .catch((e) => {
          loader.dismiss();
        });
    } else {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.PLEASECONNECTTONETWORK, ['OK']);
    }

  }
  async onSubmitUserClick() {

    if (this.userData.FirstName == "") {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.FIRSTNAME, ['OK']);
      return;
    } else if (this.userData.LastName == "") {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.LASTNAME, ['OK']);
      return;
    } else if (this.userData.Email == "") {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.EMAIL, ['OK']);
      return;
    } else if (this.userData.Password == "" && this.isEditUser == false) {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.PASSWORD, ['OK']);
      return;
    } else if (this.userData.OrganizationID == 0) {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.ORGID, ['OK']);
      return;
    }
    if (navigator.onLine) {
      const loader = await this.commonService.showLoading(messages.SAVEUSER);
      var tempGarageList = this.garageList.filter(item => item.isChecked == true);
      if (tempGarageList.length > 0) {

        for (var temp = 0; temp < tempGarageList.length; temp++) {
          if (tempGarageList.length == temp + 1) {
            this.userData.ControlItemIDs = this.userData.ControlItemIDs + tempGarageList[temp].ControlItemID;
          } else {
            this.userData.ControlItemIDs = this.userData.ControlItemIDs + tempGarageList[temp].ControlItemID + ',';
          }
        }
      }


      this.userData.LoggedInEmail = this.globals.userInfo.userName;
      if (this.userData.Password != undefined && this.userData.Password != null && this.userData.Password != '') {
        var md5Passowrd = Md5.init(this.userData.Password);
        this.userData.Password = md5Passowrd;
      }else{
        this.userData.Password = this.existingPassword;
      }
      // if(this.isEditUser == true){
      //   this.userData.Password = null;
      // }
      const body = this.userData;
      this.userService
        .saveUser(body)
        .then((data: any) => {
          loader.dismiss();
          if (data.Message == 'SUCCESS') {
            this.dismiss(false);
            if (this.userData.UserID == this.globals.userInfo.LoggedInUserID) {
              // this.storage.remove('username');
              // this.storage.set('username', this.userData.Email);
              // this.globals.userInfo.userName = this.userData.Email;

              this.storage.remove('username');
              this.storage.remove('password');
              this.storage.remove('firstName');
              this.storage.remove('lastName');

              this.storage.set('username', this.userData.Email);
              this.storage.set('password', this.userData.Password);
              this.storage.set('firstName', this.userData.FirstName);
              this.storage.set('lastName', this.userData.LastName);
             
              this.globals.userInfo.userName = this.userData.Email;
              this.globals.userInfo.password = this.userData.Password;
              this.globals.userInfo.firstName = this.userData.FirstName;
              this.globals.userInfo.lastName = this.userData.LastName;
              

            }
          }
        })
        .catch((e) => {
          loader.dismiss();
          console.log(e.error.text);
          this.commonService.showAlert(messages.ALERTTITLINFORMATION, e.error.text, ['OK']);
        });
    } else {
      this.commonService.showAlert(messages.ALERTTITLINFORMATION, messages.PLEASECONNECTTONETWORK, ['OK']);
    }
  }
  onChangeRadioEvent($event) {
    console.log('status ' + $event.target.value);
    this.userData.IsActive = $event.target.value;
  }
  onChangeOrgRadioEvent($event) {
    console.log('orgid ' + $event.target.value);
    this.userData.OrganizationID = $event.target.value;
  }
  onChangePortalAccessRadioEvent($event) {
    console.log('HasAccessToPortal ' + $event.target.value);
    this.userData.HasAccessToPortal = $event.target.value;
  }
  onChangeOverrideGeoFenceRadioEvent($event) {
    console.log('Override geofence ' + $event.target.value);
    this.userData.OverRideGeoFence = $event.target.value;
  }
  

}
