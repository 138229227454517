import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CustomHeaderComponent } from './component/custom-header/custom-header.component';
import { MenuDetails } from './shared/constants';
import { HttpClientModule } from '@angular/common/http';
import { RequestHelper } from './shared/requesthelper';
import { AddorganizationmodalPage } from './modals/addorganizationmodal/addorganizationmodal.page';
import { FormsModule } from '@angular/forms';
import { AddusersmodalPage } from './modals/addusersmodal/addusersmodal.page';
import { NavParams } from '@ionic/angular';
import { AddgaragemodalPage } from './modals/addgaragemodal/addgaragemodal.page'
import { AddgaragefreezehistorymodalPage } from './modals/addgaragefreezehistorymodal/addgaragefreezehistorymodal.page'
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { IonicStorageModule } from '@ionic/storage';
import { Geolocation } from '@ionic-native/geolocation/ngx';
@NgModule({
  declarations: [AppComponent, AddorganizationmodalPage, AddusersmodalPage, AddgaragemodalPage, AddgaragefreezehistorymodalPage],
  entryComponents: [AddorganizationmodalPage, AddusersmodalPage, AddgaragemodalPage, AddgaragefreezehistorymodalPage],
  imports: [BrowserModule, IonicModule.forRoot(), IonicStorageModule.forRoot(), AppRoutingModule, HttpClientModule, FormsModule],
  providers: [
    CustomHeaderComponent,
    StatusBar,
    SplashScreen, MenuDetails, RequestHelper, AddusersmodalPage, NavParams,Geolocation,
    //{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
