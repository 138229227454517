import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../shared/common';
import { RequestHelper } from '../shared/requesthelper';
import { GlobalsService } from '../shared/globals';
import { Constants } from '../shared/constants';
import { timeout } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ActivityhistoryService {

  constructor(public http: HttpClient,
    public commonService: CommonService,
    public requestHelper: RequestHelper) { }

    getActivityHistory(count) {
      return new Promise((resolve, reject) => {
        const url = this.requestHelper.GetUrlForActivityHistory(count);
        const reqHeaders = this.requestHelper.getRequestHeader();
        this.http.get(url, { headers: reqHeaders })
          .pipe(timeout(Constants.TIMEOUT))
          .subscribe(
            (data: any) => {
              resolve(data.ActivityHistory);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
    GetGarageFreezeActivityHistoryData(controlItemID) {
      return new Promise((resolve, reject) => {
        const url = this.requestHelper.GetUrlForGarageFreezeActivityHistoryData(controlItemID);
        const reqHeaders = this.requestHelper.getRequestHeader();
        this.http.get(url, { headers: reqHeaders })
          .pipe(timeout(Constants.TIMEOUT))
          .subscribe(
            (data: any) => {
              resolve(data.ActivityHistory);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
}
